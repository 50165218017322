//==================================================================
// Print Stylesheet
// Styles for webpage printing.
// (c) Atlantic BT. All Rights Reserved.
//==================================================================

* {
  background: transparent !important;
  color: black !important;
  box-shadow: none !important;
  text-shadow: none !important;
  filter: none !important;
  -ms-filter: none !important;
}

a {
  text-decoration: underline;
  &:visited {
    text-decoration: underline;
  }
  &[href]:after {
    content: " (" attr(href) ")";
  }
}
.card.service .meta [itemprop="url"] {
  padding: 0;
}
abbr[title]:after {
  content: " (" attr(title) ")";
}

.ir a:after {
  content: "";
}

a {
  &[href^="javascript:"]:after, &[href^="#"]:after {
    content: "";
  }
}

pre, blockquote {
  border: 1px solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group;
}

tr {
  page-break-inside: avoid;
}

img {
  page-break-inside: avoid;
  max-width: 100% !important;
  float: none !important;
}

@page {
  margin: 0.5cm;
}

p, h2, h3 {
  orphans: 3;
  widows: 3;
}

h2, h3 {
  page-break-after: avoid;
}

.header-container,
.user-feedback,
.footer-container,
.alert-box {
  display: none;
}

.band {
  padding:0;
  padding-top: 30px;
  .pad-large {
    padding:0;
  }
}
.quick-links.primary {
  padding: 0;
}
input[type=button].button.ghost.inverted,
input[type=submit].button.ghost.inverted,
button.button.ghost.inverted,
a.button.ghost.inverted {
  border: 2px solid #000;
}
.card.cta,
.card.govt {
  text-align: left;
  .meta {
    padding: 0;
  }
}
.email-signup {
  display: none;
}
.card.event [itemprop="photo"]:before {
  background: none;
}
.card.event [itemprop="photo"] [itemprop="startDate"] {
  position: relative;
  margin-top: 30px;
  bottom: 0;
  left: 0;
}